module controllers {
    export module applicationcore {
        export class unauthorisedCtrl {

            static $inject = ['$scope', '$rootScope', '$stateParams', '$location'];

            private url: string;

            constructor(private $scope: ng.IScope, private $rootScope: interfaces.applicationcore.IRootScope,
                private $stateParams: any, private $location: ng.ILocationService) {

                this.url = $location.protocol() + "://" + $location.host() + "/" + $stateParams.url;
                $location.url("/login");
            }
        };

        angular.module("app").controller("unauthorisedCtrl", controllers.applicationcore.unauthorisedCtrl);
    }
}